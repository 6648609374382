import React, { useState, useEffect } from "react";
import css from "./index.module.scss";
import Lists from "../Lists";
import Mine from "../Mine";
import { AddCircleOutline } from "antd-mobile-icons";

function Index() {
    const [tab, setTab] = useState(0);

    const changeTab = (index) => {
        setTab(index);
    }

    return (
        <div className={css.container}>
            <div className={css.content}>
                {tab === 0 ? <Lists /> : <Mine />}
            </div>
            <div className={css.tab}>
                <div className={css.item} onClick={() => changeTab(0)}>
                    <div className={`${css.icon} ${tab === 0 ? css.homes : css.home}`}></div>
                    <div className={`${css.text} ${tab === 0 ? css.texts : ''}`}>首页</div>
                </div>
                <div className={css.item}>
                    <div className={css.icon2}><AddCircleOutline fontSize={60} /></div>
                    <div className={`${css.text}`}>立即创建</div>
                </div>
                <div className={css.item} onClick={() => changeTab(1)}>
                    <div className={`${css.icon} ${tab === 1 ? css.mines : css.mine}`}></div>
                    <div className={`${css.text} ${tab === 1 ? css.texts : ''}`}>我的</div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index);