//写一个移动端登录页面，函数组件，带去注册
import React, { useState } from 'react';
import { Button, Input, Toast } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserCircleOutline, HandPayCircleOutline, EyeOutline } from "antd-mobile-icons";
import css from './login.module.scss';
import { login } from '../../api';
import { TOKEN_NAME } from '../../utils/auth';
import { useDispatch } from 'react-redux';
import { getInfoByToken } from '../../store/features/userInfoSlice';

function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [isUser, setIsUser] = useState(true);
    const openId = new URLSearchParams(location.search).get('openId');
    const redirectPath = new URLSearchParams(location.search).get('url');
    if (openId) localStorage.setItem('openId', openId);
    if (redirectPath) localStorage.setItem('redirect_path', redirectPath);

    const handleLogin = async () => {
        if (!username) {
            Toast.show('请输入用户名');
            return;
        }
        if (!password) {
            Toast.show('请输入密码');
            return;
        }
        setLoading(true);
        try {
            const openId = localStorage.getItem('openId');
            const reqdata = { nickname: username, password, flag: 1 };
            if (openId) reqdata.openId = openId;
            const result = await login(reqdata);
            if (result.code === 200) {
                localStorage.setItem(TOKEN_NAME, result.data);
                Toast.show('登录成功');
                dispatch(getInfoByToken());
                const redirect = localStorage.getItem('redirect_path');
                if (redirect) {
                    localStorage.removeItem('redirect_path');
                    navigate(redirect);
                } else {
                    navigate('/home');
                }
                // navigate('/home');
            } else {
                Toast.show(result.message ?? '登录失败');
            }
        } catch (err) {
            Toast.show('登录失败');
        } finally {
            setLoading(false);
        }
    };

    const switchIsUser = () => {
        setIsUser(!isUser);
    }

    const goRegister = () => {
        navigate('/register');
    }
    const goLoginMerchant = () => {
        navigate('/loginMerchant');
    }

    return (
        <div className={css.container}>
            <div className={css.login}>
                <div>
                    {isUser ? <UserCircleOutline className={css.icon} /> : <HandPayCircleOutline className={css.icon} />}
                </div>
                {/* 跳转前在localstorage中保存要返回的路径，若没有则返回首页 */}
                {/* <div className={css.selectzone}>
                    <div className={`${css.item} ${isUser ? css.choose : ''}`} onClick={switchIsUser}>用户登录</div>
                    <div className={`${css.item} ${isUser ? '' : css.choose}`} onClick={switchIsUser}>商家登录</div>
                </div> */}
                <Input
                    className={css.input}
                    placeholder="请输入用户名"
                    value={username}
                    onChange={setUsername}
                >用户名</Input>
                <Input
                    className={css.input}
                    placeholder="请输入密码"
                    type="password"
                    value={password}
                    onChange={setPassword}
                ><EyeOutline /></Input>
                <Button type="primary" onClick={handleLogin} loading={loading} className={css.loginbtn}>登录</Button>
                {isUser ? <div className={css.register} onClick={goRegister}>去注册</div> : <div className={css.contract}>联系我们</div>}
                {isUser ? <div className={css.contract} onClick={goLoginMerchant} style={{ marginTop: '15px' }}>切换到商家版</div> : null}
            </div>
        </div>
    );
}

export default React.memo(Login);