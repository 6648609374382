import React, { useState } from 'react';
import css from './comp.module.scss';
// import { v4 as uuid } from 'uuid';
import { Stepper, Popup } from 'antd-mobile';
import '../quill.snow.css';

function Index(props) {
    const [visible, setVisible] = useState(false);
    const openModal = () => {
        setVisible(true);
    }
    const colseModal = () => {
        setVisible(false);
    }

    return (
        <div className={css.product}>
            <div className={css.top} onClick={openModal}>
                {props.pic ? <div className={css.pic}>
                    <img src={props.pic} alt="" />
                </div> : null}
                <div className={`${css.info} ${props.pic ? '' : css.info2}`}>
                    <div className={css.title2}>{props.title}</div>
                    {props.brief ? <div className={css.brief2}>{props.brief}</div> : null}
                    <div className={css.price}>￥{props.price}</div>
                </div>
            </div>
            <div className={css.bottom}>
                <div>数量</div>
                <Stepper
                    className={css.stepper}
                    min={props.min}
                    max={props.max}
                    value={props.value}
                    onChange={props.onChange}
                    style={{ '--button-background-color': '#006efd', '--button-text-color': '#fff' }}
                />
            </div>
            <Popup
                visible={visible}
                onMaskClick={colseModal}
                onClose={colseModal}
                showCloseButton
                destroyOnClose
                bodyStyle={{ height: '80vh' }}
            >
                {<Detail {...props} />}
            </Popup>
        </div>
    )
}

function Detail(props) {
    return (<div className={`${css.product} ${css.detail}`}>
        <div className={`${css.top} ${css.clearfix}`}>
            {props.pic ? <div className={css.pic}>
                <img src={props.pic} alt="" />
            </div> : null}
            <div className={`${css.info} ${props.pic ? '' : css.info2}`}>
                <div className={css.title}>{props.title}</div>
                {props.brief ? <div className={css.brief}>{props.brief}</div> : null}
                <div className={css.price}>￥{props.price}</div>
            </div>
        </div>
        <div className={css.bottom}>
            <div>数量</div>
            <Stepper
                className={css.stepper}
                defaultValue={props.min ?? 0}
                min={props.min}
                max={props.max}
                value={props.value}
                onChange={props.onChange}
                style={{ '--button-background-color': '#006efd', '--button-text-color': '#fff' }}
            />
        </div>
        <div className={css.contain}>
            <div
                className={`${css.content} ql-editor`}
                dangerouslySetInnerHTML={{ __html: props.desc }}
            ></div>
        </div>
    </div>)
}

export default React.memo(Index);