import css from './index.module.scss';
import React, { useState, useEffect } from 'react';
import { doPay2, getInfo2 } from '../../api/index';
import { Form as Mform, Input, Selector, Button, Toast, SpinLoading } from 'antd-mobile';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeftOutline } from 'antd-mobile-icons';
import './quill.snow.css';
import { getDomByType } from './func.jsx';
import Big from 'big.js';

const ignoreList = ['remark'];

function App() {
    const [top, setTop] = useState({});
    const [listdata, setData] = useState([]);
    const [phoneForm] = Mform.useForm();
    const [pid, setId] = useState('');
    const [tempId, setTempId] = useState('');
    const [shopId, setShopId] = useState('');
    const [loading, setLoading] = useState(false);
    const [getloading, setGetLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setShopId(new URLSearchParams(location.search).get('shopId'));
        const id = new URLSearchParams(location.search).get('id');
        if (id) {
            getData(id);
        }
    }, [location.search]);
    const getData = async (id) => {
        setGetLoading(true);
        try {
            setId(id);
            const res = await getInfo2({ id: id });
            if (res.code === 200) {
                const data = JSON.parse(res.data.templat.content);
                setTempId(res.data.templat.id);
                setTop({ title: res.data.templat.name, pic: data.topPic });
                setData(data.itemList.map((item) => {
                    if (item.type === 'timePicker') {
                        if (item.timerange) item.timerange = item.timerange.map(e => dayjs(e));
                    }
                    return item;
                }));
            } else {
                Toast.show({ content: res.message || '获取数据失败', icon: 'fail' });
            }
        } catch (e) {
            Toast.show({ content: '获取数据失败', icon: 'fail' });
        } finally {
            setGetLoading(false);
        }
    }

    const validate = (val) => {
        const keys = Object.keys(val);
        for (let key of keys) {
            const item = listdata.find(e => +e.id === +key);
            if (item.required && !val[key]) {
                Toast.show({ content: item.title + '不得为空', icon: 'fail' });
                return false;
            }
        }
        return true;
    }

    const submit = async (val) => {
        if (!validate(val)) return;
        const keys = Object.keys(val);
        const dataList = [];
        let count = 0;
        for (let key of keys) {
            let temp = val[key];
            const item = listdata.find(e => +e.id === +key);
            if (item.type === 'pay') count = Big(count).plus(+temp);
            else if (item.type === 'product') {
                count = Big(count).plus(Big(+item.price).times(temp ?? 0));
                temp = { price: item.price, count: (temp ?? 0) };
            } else if (item.type === 'protocal') {
                if (!temp) return Toast.show({ content: '请先同意协议', icon: 'fail' });
                continue;
            } else if (ignoreList.includes(item.type)) {
                continue;
            }
            dataList.push({ title: item.title, value: temp, id: item.id });
        }
        if (!shopId) return Toast.show({ content: '缺少商户信息', icon: 'fail' });
        setLoading(true);
        const reqdata = {
            amount: count.round(2),
            tempId: tempId,
            projectId: pid,
            shopId: shopId,
            content: JSON.stringify(dataList)
        }
        const res = await doPay2(reqdata);
        if (res.code && res.code !== 200) {
            Toast.show({ content: res.msg || '交易失败', icon: 'fail' });
            setLoading(false);
        } else if (res["qr_url"]) window.location.href = res["qr_url"];
        else {
            Toast.show({ content: res.msg || '交易失败', icon: 'fail' });
            setLoading(false);
        }
    }
    const goBack = () => {
        navigate('/home');
    }

    return (
        <div className={css.phone}>
            <div className={`${css.topbar} ${top.pic ? css.topbar2 : ''}`}>
            </div>
            <div className={css.titlebar}>
                <LeftOutline className={css.back} onClick={goBack} />
                <div className={`${css.title} `}>{top.title ? top.title : '标题'}</div>
            </div>
            <div className={`${css.imgbox} ${top.pic ? css.selectbox : ''}`}>
                {top.pic ? <img src={top.pic} alt="" /> : <></>}
            </div>
            <div className={`${css.itembox} ${top.pic ? css.hasPic : ''}`}>
                <Mform
                    layout={"horizontal"}
                    form={phoneForm}
                    name="phoneForm"
                    onFinish={submit}
                    onFinishFailed={(val) => {
                        console.log(val);
                    }}
                    style={{ '--border-inner': 'none', '--border-top': 'none', '--border-bottom': 'none', '--adm-color-background': '#f5f5f5', '--adm-color-box': '#ffffff' }}
                >
                    {listdata.map((item) => getDomByType(item, phoneForm))}
                    <div className={css.flexzone}>
                        <Button type="primary" htmlType="submit" className={css.bigbtn} loading={loading}>
                            提交
                        </Button>
                    </div>
                </Mform>
            </div>
            {getloading ? <div className={css.mask}>
                <SpinLoading style={{ '--size': '48px' }} color="#1296db"></SpinLoading>
            </div> : null}
        </div>
    );
}

export default React.memo(App);
