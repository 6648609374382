import React, { useState, useEffect } from 'react';
import css from './comp.module.scss';
// import { v4 as uuid } from 'uuid';
import { Popup, Checkbox } from 'antd-mobile';
import { MobilePDFReader } from 'react-read-pdf';
import { useNavigate } from 'react-router-dom';

function Index(props) {
    const [visible, setVisible] = useState(false);
    const [url, setUrl] = useState('');
    const navigate = useNavigate();
    const openModal = (url) => {
        // navigate(`/protocal?url=${url}`);
        setUrl(url);
        setVisible(true);
    }
    const colseModal = () => {
        setUrl('');
        setVisible(false);
    }

    const handleError = (error) => {
        console.log('error', error);
    }

    useEffect(() => {
        window.addEventListener('error', handleError);
    }, []);

    return (
        <div className={css.protocal}>
            <div className={css.top}>
                <Checkbox style={{ '--font-size': '12px' }} onChange={(val) => props.onChange(val)}>
                    {props.text ?? '签署'}
                    {(props.protocol ?? []).map((item, index) => (
                        <span key={index} onClick={() => openModal(item.url)}>{`《${item.newName ?? item.name.split('.')[0]}》`}</span>
                    ))}
                </Checkbox>
            </div>
            <Popup
                visible={visible}
                onClose={colseModal}
                showCloseButton
                destroyOnClose
                bodyStyle={{ overflow: 'hidden', height: '90vh' }}
            >
                <div style={{ width: '100%', overflowY: 'scroll' }}>
                    <MobilePDFReader url={url} isShowHeader={false} />
                </div>
            </Popup>
        </div>
    )
}

export default React.memo(Index);