const TOKEN_NAME = 'jiaofei_token';

function getToken() {
    const token = localStorage.getItem(TOKEN_NAME) ? localStorage.getItem(TOKEN_NAME) : '';
    if (token) {
        return token;
    }
    const env = environment();
    if (env === 'wechat') {
        weixinAuthorize(window.location.pathname + window.location.search);
        return false;
    }
    if (window.location.pathname !== 'login') localStorage.setItem('redirect_path', window.location.pathname + window.location.search);
    window.location.href = '/login';
    return false;
}

function reLogin() {
    const env = environment();
    if (env === 'wechat') {
        weixinAuthorize(window.location.pathname + window.location.search);
        return false;
    }
    if (window.location.pathname !== 'login') localStorage.setItem('redirect_path', window.location.pathname + window.location.search);
    window.location.href = '/login';
    return false;
}

//字符串用base64编码
function base64Encode(str) {
    return window.btoa(str);
}

function weixinAuthorize(str) {
    // axios.get('http://120.76.212.58:7002/api/member/authorize').then(res => {
    //     console.log(res);
    // }).catch(err => {
    //     console.log(err);
    // })
    window.location.href = 'http://fenqigo.runpos.com/api/api/member/authorize?url=' + base64Encode(str);
}


function environment() {
    const useragent = navigator.userAgent.toLowerCase();
    if (useragent.indexOf('micromessenger') > -1) {
        return 'wechat';
    }
    if (useragent.indexOf('alipay') > -1) {
        return 'alipay';
    }
    return 'web';
}

export { getToken, TOKEN_NAME, reLogin }