import css from './index.module.scss';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Toast } from 'antd-mobile';

const statusList = [
    { value: 0, label: "待付款" },
    { value: 1, label: "待配送" },
    { value: 2, label: "待收货" },
    { value: 3, label: "已完成" },
    { value: 4, label: "支付成功" },
    { value: 5, label: "已取消" },
    { value: 6, label: "无效订单" },
    { value: 11, label: "申请退款中" },
    { value: 12, label: "退款完成" },
    { value: 13, label: "拒绝退款" },
    { value: 14, label: "退款取消" },
    { value: 15, label: "部分退款完成" }
]
const orderTypeList = [
    { value: 1, label: "普通收款" },
    { value: 2, label: "账单收款" },
    { value: 3, label: "订单收款" },
]

function Index() {
    const location = useLocation();
    const [orderInfo, setOrderInfo] = useState({});
    const [curlist, setCurList] = useState([]);

    useEffect(() => {
        setOrderInfo(location.state);
        formatList(location.state);
    }, []);

    const formatStatus = (status) => {
        const item = statusList.find((i) => i.value === +status);
        return item ? item.label : "";
    }
    const formatOrderType = (type) => {
        const item = orderTypeList.find((i) => i.value === +type);
        return item ? item.label : "";
    }
    const formatList = (state) => {
        try {
            const itemList = JSON.parse(state.content).itemList ?? [];
            const list = (JSON.parse(state.orderInfo) ?? []).map((item) => {
                const info = itemList.find(e => e.id === item.id);
                if (!info) return false;
                if (info.type === 'check') {
                    item.value = Array.isArray(item.value) ? item.value : item.value === undefined ? [] : [item.value];
                    item.value = item.value.map((v) => {
                        return info.choose.find((o) => o.value === v).label;
                    }).join(",");
                } else if (info.type === 'remark') return false;
                else if (info.type === 'product') item.value = (item.value.price && item.value.count) || item.value.count === 0 || item.value.price === 0 ? '单价:¥' + item.value.price + '（数量：' + item.value.count + ' ）' : '';
                return item;
            }).filter(e => e);
            setCurList(list);
        } catch (err) {
            console.log(err);
        }
    }

    function copyContentH5(content) {
        try {
            var copyDom = document.createElement('div');
            copyDom.innerText = content;
            copyDom.style.position = 'absolute';
            copyDom.style.top = '0px';
            copyDom.style.right = '-9999px';
            document.body.appendChild(copyDom);
            //创建选中范围
            var range = document.createRange();
            range.selectNode(copyDom);
            //移除剪切板中内容
            window.getSelection().removeAllRanges();
            //添加新的内容到剪切板
            window.getSelection().addRange(range);
            //复制
            var successful = document.execCommand('copy');
            copyDom.parentNode.removeChild(copyDom);
            Toast.show({ content: successful ? '复制成功' : '复制失败', icon: successful ? 'success' : 'fail' });
        } catch (err) {
            console.log('Oops , unable to copy!');
        }
    }

    return (
        <div className={css.container}>
            <div className={css.box}>
                <div className={css.item}>
                    <div className={css.label}>订单编号</div>
                    <div className={css.value}>{orderInfo.orderNo}<span onClick={() => copyContentH5(orderInfo.orderNo)}>复制</span></div>
                </div>
                <div className={css.item}>
                    <div className={css.label}>项目名称</div>
                    <div className={css.value}>{orderInfo.projectName}</div>
                </div>
                <div className={css.item}>
                    <div className={css.label}>订单状态</div>
                    <div className={css.value}>{formatStatus(orderInfo.orderStatus)}</div>
                </div>
                <div className={css.item}>
                    <div className={css.label}>订单类型</div>
                    <div className={css.value}>{formatOrderType(orderInfo.orderType)}</div>
                </div>
                <div className={css.item}>
                    <div className={css.label}>商户名称</div>
                    <div className={css.value}>{orderInfo.shopName}</div>
                </div>
                <div className={css.item}>
                    <div className={css.label}>支付方式</div>
                    <div className={css.value}>{+orderInfo.payType === 0 ? '微信' : +orderInfo.payType === 1 ? '支付宝' : '其他'}</div>
                </div>
                <div className={css.item}>
                    <div className={css.label}>支付时间</div>
                    <div className={css.value}>{orderInfo.payTime ? orderInfo.payTime.replace('T', ' ') : ''}</div>
                </div>
                <div className={css.item}>
                    <div className={css.label}>订单金额</div>
                    <div className={css.value} style={{ fontSize: '16px', fontWeight: 'bold' }}>¥{orderInfo.orderAmount}</div>
                </div>
            </div>

            {curlist.length ? <div className={css.box}>
                {curlist.map((item, index) => (
                    <div key={index} className={css.item}>
                        <div className={css.label}>{item.title}</div>
                        <div className={css.value}>{item.value}</div>
                    </div>
                ))}
            </div> : null}
        </div>
    )
}

export default React.memo(Index);