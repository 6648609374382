import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import router from './router/index';
import { useDispatch } from 'react-redux';
import { getInfoByToken } from './store/features/userInfoSlice';
import { SafeArea } from 'antd-mobile'

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInfoByToken());
  }, [dispatch]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div >
        <SafeArea position='top' />
      </div>
      {useRoutes(router)}
      <div >
        <SafeArea position='bottom' />
      </div>
    </div>
  );
}

export default App;
