import React, { useState, useEffect } from 'react';
import css from './comp.module.scss';
import { MobilePDFReader } from 'react-read-pdf';
import { useLocation } from 'react-router-dom';
import jsPreviewPdf from "@js-preview/pdf";
import { download } from '../../../api';

function Index() {
    const location = useLocation();
    //解析location.search中的url参数
    const [url, setUrl] = useState('');
    const [data, setData] = useState(null);
    const [myPdfPreviewer, setMyPdfPreviewer] = useState(null);

    const downloadPdf = async () => {
        const url = new URLSearchParams(location.search).get('url');
        const res = await download({ fileName: url.split('/').pop() });
        if (res) {
            console.log(res);
            const item = jsPreviewPdf.init(document.getElementById('pdf'), {
            });
            //Arraybuffer
            const blob = new Blob([res], { type: 'application/pdf' });

            setMyPdfPreviewer(item);
            item.preview(blob);
        }
    }

    useEffect(() => {
        downloadPdf();
        return () => {
            myPdfPreviewer && myPdfPreviewer.destroy();
        }
    }, []);

    return (
        <div id='pdf'></div>
    )
}

export default React.memo(Index);