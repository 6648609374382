import React, { useState, useEffect } from "react";
import css from "./index.module.scss";
import { getBill, getRecord, refundApply } from "../../api";
import { SpinLoading, Toast, InfiniteScroll, DotLoading, List } from "antd-mobile";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const statusList = [
    { value: 0, label: "待付款" },
    { value: 1, label: "待配送" },
    { value: 2, label: "待收货" },
    { value: 3, label: "已完成" },
    { value: 4, label: "支付成功" },
    { value: 5, label: "已取消" },
    { value: 6, label: "无效订单" },
    { value: 11, label: "申请退款中" },
    { value: 12, label: "退款完成" },
    { value: 13, label: "拒绝退款" },
    { value: 14, label: "退款取消" },
    { value: 15, label: "部分退款完成" }
]

const Pay = React.memo(() => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    const goPay = (id, shopId) => {
        navigate(`/deco?id=${id}&shopId=${shopId}`);
    }
    const getBills = async () => {
        setLoading(true);
        try {
            const res = await getBill();
            if (res.code === 200) {
                setList(res.data);
            } else {
                Toast.show({ content: res.message || "获取数据失败", icon: "fail" });
            }
        } catch (error) {
            Toast.show({ content: "获取数据失败", icon: "fail" });
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        getBills();
    }, []);

    return (
        <div className={css.list}>
            {list.map((item) => (
                <div key={item.id} className={css.item}>
                    <div className={css.left}>
                        <div className={css.name}>{item.name}</div>
                        <div className={css.shop}>{item.shopName}</div>
                        <div className={css.time}>截止时间：{item.endTime}</div>
                    </div>
                    <div className={css.right}>
                        <div className={css.btn} onClick={() => goPay(item.id, item.shopId)}>去支付</div>
                    </div>
                </div>
            ))}
        </div>
    )
})

const InfiniteScrollContent = ({ hasMore }) => {
    return (
        <>
            {hasMore ? (
                <>
                    <span>加载中</span>
                    <DotLoading />
                </>
            ) : (
                <span>--- 没有更多啦 ---</span>
            )}
        </>
    )
}

const Record = React.memo(() => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const pageSize = 10;
    const [pageNum, setPageNum] = useState(1);

    const getList = async () => {
        setLoading(true);
        const req = {
            size: pageSize,
            current: pageNum
        }
        try {
            const res = await getRecord(req);
            if (res.code === 200) {
                setList([...list, ...res.data.records]);
                setHasMore(res.data.records.length > 0);
                setPageNum(pageNum + 1);
            } else {
                Toast.show({ content: res.message || "获取数据失败", icon: "fail" });
                setHasMore(false);
            }
        } catch (error) {
            Toast.show({ content: "获取数据失败", icon: "fail" });
            setHasMore(false);
        } finally {
            setLoading(false);
        }

    }
    const formatStatus = (status) => {
        const item = statusList.find((i) => i.value === +status);
        return item ? item.label : "";
    }
    const goDetail = (info) => {
        navigate('/orderInfo', { state: info });
    }
    const goRefund = async (id) => {
        try {
            const res = await refundApply({ orderId: id });
            if (res.code === 200) {
                Toast.show({ content: '申请退款成功', icon: 'success' });
                setList(list.map((i) => {
                    if (i.id === id) {
                        i.orderStatus = 11;
                    }
                    return i;
                }));
            } else {
                Toast.show({ content: res.message || "申请退款失败", icon: "fail" });
            }
        } catch (error) {
            Toast.show({ content: "申请退款失败", icon: "fail" });
        }
    }

    // useEffect(() => {
    //     getList();
    // }, []);

    return (
        <div className={css.records}>
            {list.map((item, index) => (
                <div key={index} className={css.items}>
                    <div className={css.top}>
                        <div className={css.left}>
                            <div className={css.shopname}>{item.shopName}</div>
                            <div className={css.time} style={{ marginTop: item.payTime ? '10px' : '0px' }}>{item.payTime}</div>
                        </div>
                        <div className={css.right}>
                            <div className={css.status} style={{ color: item.orderStatus === 4 ? '#00cc00' : '#666' }}>{formatStatus(item.orderStatus)}</div>
                            <div >¥{item.orderAmount}</div>
                        </div>
                    </div>
                    <div className={css.bot}>
                        {(item.orderType === 2 || item.orderType === 3) && item.orderStatus === 4 ? <div className={`${css.btn}`} onClick={() => goRefund(item.id)}>申请退款</div> : null}
                        <div className={css.bluebtn} onClick={() => goDetail(item)}>查看详情</div>
                    </div>
                </div>
            ))}
            <InfiniteScroll loadMore={getList} hasMore={hasMore}>
                <InfiniteScrollContent hasMore={hasMore} />
            </InfiniteScroll>
        </div>
    )
})

function Index() {
    const user = useSelector((state) => state.userInfo);
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);

    return (
        <div className={css.container}>
            <div className={css.top}>
                <div className={css.title}>你好，<span>{user && user.user ? user.user.nickname : '新用户'}</span></div>
                <div className={css.change}>
                    <div className={`${css.btn} ${tab === 0 ? css.select : ''}`} onClick={() => setTab(0)}>待缴账单</div>
                    <div className={`${css.btn} ${tab === 1 ? css.select : ''}`} onClick={() => setTab(1)}>历史记录</div>
                </div>
            </div>
            <div style={{ flex: 1, overflowY: 'scroll' }}>{tab === 0 ? <Pay></Pay> : <Record></Record>}</div>
        </div>
    );
}
export default React.memo(Index);