//写一个404页面
import React from 'react';
import { Button } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import css from './index.module.scss';
function NotFound() {
    const navigate = useNavigate();
    return (
        <div className={css.notfound}>
            <div className={css.title}>404</div>
            <div className={css.desc}>抱歉，您访问的页面不存在</div>
            <Button type="primary" onClick={() => navigate(-1)}>返回</Button>
        </div>
    );
}
export default React.memo(NotFound);
