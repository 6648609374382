import React, { useState, useEffect } from "react";
import css from "./index.module.scss";
import { Dialog, Form, Toast, CenterPopup, Button, Input } from "antd-mobile";
import { PhonebookOutline, RedoOutline, LockOutline, RightOutline, EyeOutline, EyeInvisibleOutline } from 'antd-mobile-icons';
import { useNavigate } from "react-router-dom";
import { updateUserInfo } from "../../api";
import { getInfoByToken } from '../../store/features/userInfoSlice';
import { useSelector, useDispatch } from "react-redux";

const PasswordPop = React.memo(() => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    const changePassword = async () => {
        const oldPassword = form.getFieldValue('oldPassword');
        const newPassword = form.getFieldValue('newPassword');
        if (!oldPassword || !newPassword) {
            Toast.show({ content: '请输入密码', icon: 'fail' });
            return;
        } else if (oldPassword !== newPassword) {
            Toast.show({ content: '两次密码不一致', icon: 'fail' });
            return;
        }
        try {
            const res = await updateUserInfo({ password: newPassword });
            if (res.code === 200) {
                Toast.show({ content: '修改成功', icon: 'success' });
                localStorage.clear();
                navigate('/login');
            } else {
                Toast.show({ content: res.message || '修改失败', icon: 'fail' });
            }
        } catch (error) {
            Toast.show({ content: '修改失败', icon: 'fail' });
        }
    }

    return (
        <div className={css.changepwd}>
            <Form form={form} style={{ '--border-top': 'none', '--border-bottom': 'none', '--adm-color-box': '#ffffff' }}>
                <Form.Item
                    label="原密码"
                    name="oldPassword"
                    rules={[{ required: true, message: '请输入原密码' }]}
                >
                    <div className={css.password}>
                        <Input placeholder='请输入密码'
                            type={visible ? 'text' : 'password'}
                        />
                        <div className={css.eye}>
                            {!visible ? (
                                <EyeInvisibleOutline onClick={() => setVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVisible(false)} />
                            )}
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                    label="新密码"
                    name="newPassword"
                    rules={[{ required: true, message: '请输入新密码' }]}
                >
                    <div className={css.password}>
                        <Input placeholder='请输入密码'
                            type={visible ? 'text' : 'password'}
                        />
                        <div className={css.eye}>
                            {!visible ? (
                                <EyeInvisibleOutline onClick={() => setVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVisible(false)} />
                            )}
                        </div>
                    </div>
                </Form.Item>
            </Form>
            <Button className={css.submit} onClick={changePassword}>修改</Button>
        </div>
    )
})

const PhonePop = React.memo(({ set }) => {
    const [form2] = Form.useForm();
    const user = useSelector((state) => state.userInfo);
    const [phoneNum, setPhoneNum] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(user.user);
        if (user && user.user.phone) setPhoneNum(user.user.phone);
    }, []);

    const change = async () => {
        if ((/^1[3456789]\d{9}$/).test(phoneNum) === false) {
            Toast.show({ content: '请输入正确的手机号', icon: 'fail' });
            return;
        }
        try {
            const res = await updateUserInfo({ phone: phoneNum });
            if (res.code === 200) {
                Toast.show({ content: '修改成功', icon: 'success' });
                dispatch(getInfoByToken());
                set(false);
            } else {
                Toast.show({ content: res.message || '修改失败', icon: 'fail' });
            }
        } catch (error) {
            Toast.show({ content: '修改失败', icon: 'fail' });
        }
    }

    return (
        <div className={css.changepwd}>
            <Form form={form2} style={{ '--border-top': 'none', '--border-bottom': 'none', '--adm-color-box': '#ffffff' }}>
                <Form.Item
                    label="手机号"
                    rules={[{ required: true, message: '请输入手机号' }]}
                >
                    <div className={css.password}>
                        <Input placeholder='请输入手机号' value={phoneNum} onChange={(val) => setPhoneNum(val)} clearable />
                    </div>
                </Form.Item>
            </Form>
            <Button className={css.submit} onClick={change}>修改</Button>
        </div>
    )
})

function Index() {
    const navigate = useNavigate();
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const user = useSelector((state) => state.userInfo.user);

    const ConfirmLogout = () => {
        Dialog.show({
            content: '确定切换吗？',
            closeOnAction: true,
            closeOnMaskClick: true,
            actions: [
                [
                    {
                        text: '取消',
                        key: 'cancel'
                    },
                    {
                        text: '确定',
                        key: 'ok',
                        style: { color: '#006efd' },
                        onClick: () => {
                            navigate('/loginMerchant');
                        }
                    }
                ]
            ]
        })
    }
    const confirmPassword = () => {
        setVisible1(true);
    }
    const confirmPhone = () => {
        setVisible2(true);
    }

    return (
        <>
            <div className={css.title}>个人中心</div>
            <div className={css.container}>
                {/* <div className={css.top}></div> */}
                <div className={css.box}>
                    <div className={css.item}>
                        <div className={css.avatar}></div>
                        <div className={css.text}>{user.nickname ? '昵称：' + user.nickname : '用户'}</div>
                    </div>
                    <div className={css.item} onClick={confirmPhone}>
                        <PhonebookOutline className={css.icon} />
                        <div className={css.text}>修改手机号</div>
                        <RightOutline className={css.right} />
                    </div>
                    <div className={css.item} onClick={confirmPassword}>
                        <LockOutline className={css.icon} />
                        <div className={css.text}>修改密码</div>
                        <RightOutline className={css.right} />
                    </div>
                    <div className={css.item} onClick={ConfirmLogout}>
                        <RedoOutline className={css.icon} />
                        <div className={css.text}>切换到商家版</div>
                        <RightOutline className={css.right} />
                    </div>
                </div>
                <CenterPopup visible={visible1} destroyOnClose onMaskClick={() => setVisible1(false)} style={{ '--min-width': '90%' }}>
                    <PasswordPop />
                </CenterPopup>
                <CenterPopup visible={visible2} destroyOnClose onMaskClick={() => setVisible2(false)} style={{ '--min-width': '90%' }}>
                    <PhonePop set={setVisible2} />
                </CenterPopup>
            </div>
        </>
    )
}

export default React.memo(Index);