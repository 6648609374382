import React, { useState } from 'react';
import css from './comp.module.scss';
import { Stepper, Popup } from 'antd-mobile';
import '../quill.snow.css';

function Index(props) {
    const [visible, setVisible] = useState(false);
    const openModal = () => {
        setVisible(true);
    }
    const colseModal = () => {
        setVisible(false);
    }
    const changeNum = (val) => {
        props.changeNum(props.id, val);
    }

    return (
        <div className={`${css.product} ${css.withstock}`}>
            <div className={css.top} onClick={openModal}>
                {props.pic ? <div className={css.pic}>
                    <img src={props.pic} alt="" />
                </div> : null}
                <div className={`${css.info} ${props.pic ? '' : css.info2}`}>
                    <div className={css.title}>{props.name}</div>
                    {props.description ? <div className={css.brief}>{props.description}</div> : null}
                    <div className={css.price}>￥{props.price}</div>
                </div>
            </div>
            <div className={css.bottom}>
                <div>数量</div>
                {props.stock > 0 ? <Stepper
                    className={css.stepper}
                    min={0}
                    max={props.stock}
                    value={props.num}
                    onChange={changeNum}
                    style={{ '--button-background-color': '#006efd', '--button-text-color': '#fff' }}
                /> : <div style={{ color: '#666' }}>已售罄</div>}
            </div>
            <Popup
                visible={visible}
                onMaskClick={colseModal}
                onClose={colseModal}
                showCloseButton
                destroyOnClose
                bodyStyle={{ height: '80vh' }}
            >
                {<Detail {...props} changeNum={changeNum} />}
            </Popup>
        </div>
    )
}

function Detail(props) {

    return (<div className={`${css.product} ${css.detail}`}>
        <div className={css.top}>
            {props.pic ? <div className={css.pic}>
                <img src={props.pic} alt="" />
            </div> : null}
            <div className={`${css.info} ${props.pic ? '' : css.info2}`}>
                <div className={css.title}>{props.name}</div>
                {props.brief ? <div className={css.brief}>{props.brief}</div> : null}
                <div className={css.price}>￥{props.price}</div>
            </div>
        </div>
        <div className={css.bottom}>
            <div>数量</div>
            {props.stock > 0 ? <Stepper
                className={css.stepper}
                min={0}
                max={props.stock}
                value={props.num}
                onChange={props.changeNum}
                style={{ '--button-background-color': '#006efd', '--button-text-color': '#fff' }}
            /> : <div style={{ color: '#666' }}>已售罄</div>}
        </div>
        <div className={css.contain}>
            {props.description}
        </div>
    </div>)
}

export default React.memo(Index);