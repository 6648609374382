import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserInfo } from '../../api';
import { TOKEN_NAME } from '../../utils/auth';

const initialState = {
    user: null,
};

export const getInfoByToken = createAsyncThunk('userInfo/userInfoObj', async () => {
    const urlToken = new URLSearchParams(window.location.search).get('token');
    if (urlToken) {
        localStorage.setItem(TOKEN_NAME, urlToken);
    } else {
        const token = localStorage.getItem(TOKEN_NAME) ? localStorage.getItem(TOKEN_NAME) : '';
        if (!token) {
            return Promise.reject();
        }
    }
    let result = await getUserInfo();
    return result;
})

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        // add(state, { payload }) {
        //     //在当前页，点击关闭页面时，会触发useEffect的组件卸载函数，添加一条记录，此时state.Ignore保证了不添加记录
        //     if (state.historyRoute[payload.key] === state.Ignore) {
        //         state.historyRoute[payload.key] = {};
        //         return;
        //     }
        //     state.historyRoute[payload.key] = { ...payload.value };
        //     for (let i of state.historyList) {
        //         if (i.key === payload.key) {
        //             return;
        //         }
        //     }
        //     state.historyList = [...state.historyList, { key: payload.key, name: payload.value.name }];
        // },
    },
    extraReducers: {
        [getInfoByToken.fulfilled](state, { payload }) {
            if (payload.code === 200) {
                state.user = payload.data;
            }
        },
    }
})

// export const { change } = userInfoSlice.actions;

export default userInfoSlice.reducer;