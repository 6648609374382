import axios from 'axios';

// 创建axios实例
const service = axios.create({
    // baseURL: process.env.BASE_API, // api的base_url
    timeout: 50000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
    res => {
        if (res.status === 200 || res.status === 304) {
            return res.data;
        } else {
            console.log('网络不太给力哦，检查一下您的网络再试吧！')
        }
    },
    error => {
        console.log('err', error.response); // for debug
        console.log('网络不太给力哦，检查一下您的网络再试吧！')
        return Promise.reject(error);
    },
);

export default service;


